import {
    AppletDesignVersionDocument,
    AppletDesignVersionStatusEnum,
} from '@redviking/argonaut-core-ui/types/db';
import { type ExtendedEntityParameters } from '@redviking/argonaut-core-ui/src/components/EntityDetail';
import { EntityDetailMode } from '@redviking/argonaut-core-ui/src/components/EntityDetail/mode';
import { Notify } from '@redviking/argonaut-core-ui/src/notifications';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import type { Route } from 'vue-router';
import { CausalError } from '@redviking/causal-error';
import { apiTrpcHttpClient } from '@redviking/argonaut-core-ui/src/util/api-trpc';
import { validateAppletDesignVersion } from './validations/design.validations';
import { SaveResult } from 'types';
import { copyAppletDesignConfig } from './util/applet-design-util';
import { v4 as uuidv4 } from 'uuid';
import { type Latest, latestAppletDesignVersionNumber } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
import type { Overwrite } from 'utility-types';
import type { UploadableMediaObject } from 'src/media/media.util';
import type { AppletMediaItem } from '@redviking/argonaut-util/types/mes/applet-designs/v5/cell-types/media.v5.zod';

export type AppletDesignVersionEntity = {
    id: string;
    versionNumber: number;
    status: AppletDesignVersionStatusEnum;
    notes: string;
    config: Latest.AppletDesignVersionConfig;
    design: {
        id: string;
        name: string;
    };
    previousDesignVersion: {
        id: string;
        designId: string;
    } | null;
    pendingMedia: {
        [cell_id: string]: Array<Overwrite<Latest.Screen.Cells.MediaCell['attrs']['items'][number], {media: UploadableMediaObject | AppletMediaItem }>>;
    };
    ignoreUnlinkedCells: boolean;
};

export async function getAppletDesignVersion (to: Route, from?: Route, mode?: EntityDetailMode) {
    if (to.query.mode === 'create') {
        const newEntity: AppletDesignVersionEntity = {
            config: {
                header: '',
                fontFamily: 'sans-serif',
                varProviders: [
                    {
                        type: 'local',
                        id: uuidv4(),
                        outputs: [],
                        attrs: {
                            vars: [],
                        },
                    },
                ],
                lockout: null,
                macros: [],
                operatorAuth: null,
                schemaVersion: 5,
                screens: [],
                sparkplugDeviceMetrics: [],
                scripts: [],
            },
            design: {
                id: to.params.appletDesignId,
                name: 'New Applet Design',
            },
            id: to.params.appletDesignVersionId,
            notes: '',
            status: AppletDesignVersionStatusEnum.Staged,
            versionNumber: 1,
            previousDesignVersion: null,
            pendingMedia: {},
            ignoreUnlinkedCells: false,
        };
        return { entity: newEntity, originalEntity: null };
    }

    const { appletDesignVersion } = await gqlClient.request({
        document: AppletDesignVersionDocument,
        variables: {
            id: to.params.appletDesignVersionId,
        },
    });

    if (!appletDesignVersion) {
        const err = new Error('Not found');
        Notify.error(err);
        throw err;
    }

    if (appletDesignVersion.config.schemaVersion !== latestAppletDesignVersionNumber) {
        // Outdated applet designs should never be view, edited, copied, etc. in UI. Ensuring this here.
        throw new Error('Internal Error. Recieved outdated Applet Design Version that cannot be interacted with.');
    }

    const originalEntity: AppletDesignVersionEntity = {
        config: appletDesignVersion.config,
        design: {
            id: appletDesignVersion.design.id,
            name: appletDesignVersion.design.name,
        },
        id: appletDesignVersion.id,
        notes: appletDesignVersion.notes,
        status: appletDesignVersion.status,
        versionNumber: appletDesignVersion.versionNumber,
        previousDesignVersion: appletDesignVersion.previousDesignVersion || null,
        pendingMedia: {},
        ignoreUnlinkedCells: false,
    };
    const entity = { ...originalEntity };

    if (mode === EntityDetailMode.Fork) {
        entity.versionNumber++;
        entity.id = uuidv4();
        entity.status = AppletDesignVersionStatusEnum.Staged;
        entity.previousDesignVersion = {
            id: originalEntity.id,
            designId: originalEntity.design.id,
        };
    }

    if (mode === EntityDetailMode.Copy) {
        entity.versionNumber = 1;
        entity.id = uuidv4();
        entity.status = AppletDesignVersionStatusEnum.Staged;
        entity.design = {
            ...entity.design,
            id: uuidv4(),
            name: `Copy of ${entity.design.name}`,
        };
        entity.notes += `\n\nCopied from ${originalEntity.design.name} v${originalEntity.versionNumber}`;
        entity.previousDesignVersion = {
            id: originalEntity.id,
            designId: originalEntity.design.id,
        };

        entity.config = copyAppletDesignConfig(entity.config);
    }

    return { entity, originalEntity };
}

export async function saveAppletDesignVersion (payload: ExtendedEntityParameters<'appletDesignVersion'>): Promise<SaveResult> {
    const {
        entity,
        mode,
    } = payload;
    const validationResult = await validateAppletDesignVersion(entity);
    if (validationResult.status !== 'success') {
        if (validationResult.status === 'error') {
            Notify.error(validationResult.errorMessage || 'Error saving Applet Design Version');
        }
        return validationResult;
    }

    try {
        const designVersionId = entity.id === 'new' ? uuidv4() : entity.id;
        const designId = entity.design.id === 'new' ? uuidv4() : entity.design.id;

        if (mode === EntityDetailMode.Edit) {
            const updateResult = await apiTrpcHttpClient.applet.designVersion.update.mutate({
                appletDesignVersion: {
                    config: entity.config,
                    id: designVersionId,
                    notes: entity.notes,
                },
            });

            const saveResult: SaveResult = {
                status: 'success',
                route: {
                    params: {
                        appletDesignId: entity.design.id,
                        appletDesignVersionId: entity.id,
                    },
                    query: {
                        mode: EntityDetailMode.View,
                    },
                },
            };

            const newEntity: AppletDesignVersionEntity = {
                id: updateResult.id,
                versionNumber: updateResult.versionNumber,
                status: updateResult.status,
                notes: updateResult.notes,
                config: updateResult.config,
                design: {
                    id: updateResult.design.id,
                    name: updateResult.design.name,
                },
                previousDesignVersion: updateResult.previousDesignVersion,
                pendingMedia: {},
                ignoreUnlinkedCells: false,
            };
            saveResult.newEntity = newEntity;


            return saveResult;
        } else {
            const insertResult = await apiTrpcHttpClient.applet.designVersion.insert.mutate({
                appletDesignVersion: {
                    config: entity.config,
                    id: designVersionId,
                    notes: entity.notes,
                    design: {
                        id: designId,
                        name: entity.design.name,
                    },
                    previousDesignVersionId: entity.previousDesignVersion?.id,
                },
            });

            const saveResult: SaveResult = {
                status: 'success',
                route: {
                    params: {
                        appletDesignId: designId,
                        appletDesignVersionId: designVersionId,
                    },
                    query: {
                        mode: EntityDetailMode.View,
                    },
                },
            };

            const newEntity: AppletDesignVersionEntity = {
                id: insertResult.id,
                versionNumber: insertResult.versionNumber,
                status: insertResult.status,
                notes: insertResult.notes,
                config: insertResult.config,
                design: {
                    id: insertResult.design.id,
                    name: insertResult.design.name,
                },
                previousDesignVersion: insertResult.previousDesignVersion,
                pendingMedia: {},
                ignoreUnlinkedCells: false,
            };
            saveResult.newEntity = newEntity;

            return saveResult;
        }
    } catch (err) {
        Notify.error(CausalError.from({
            message: 'Error saving Applet Design Version',
            cause: err,
        }));
        return {
            status: 'error',
        };
    }
}
