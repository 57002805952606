/* eslint-disable no-await-in-loop */
/* eslint-disable max-lines */
/* eslint max-depth: [error,7] */
import type { EntityTypeMap } from 'types/entity';
import { SaveResult } from 'types/routes';
import { messages } from '@redviking/argonaut-core-ui/src/i18n/i18n';
import { Notify } from '@redviking/argonaut-core-ui/src/notifications';
import { EntityDetailMode } from '@redviking/argonaut-core-ui/src/components/EntityDetail/mode';
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import type { AppletDesignState } from '@redviking/argonaut-core-ui/src/applet/design/appletDesign.store';
import { getReferencedAppletVars } from '@redviking/argonaut-util/src/argo-lang/parser';
import { varpTypeMap } from '../varps/varpTypeMap';
import {
    type TabRoute,
    generalTabRoute,
    screensTabRoute,
    varsTabRoute,
} from '../util/consts';
import macroTypeMap from '../macros/macroTypeMap';
import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
import { getUnlinkedCell } from './unlinked-cells';
import { validateScript } from '../monaco-language/monacoConfig';
import { CausalError } from '@redviking/causal-error';

async function validateScripts (): Promise<string> {
    const entityData = accessor.entityAsType('appletDesignVersion');
    if (!entityData) {
        return screensTabRoute;
    }
    if (!entityData.config.scripts) {
        return '';
    }

    for (const scriptCfg of entityData.config.scripts) {
        if (!scriptCfg.disableGrammarValidation) {
            const scriptOrigin = accessor.appletDesign.findScriptOrigin(scriptCfg.id);
            let varpId: string | undefined;
            if (scriptOrigin?.type === 'varp') {
                varpId = scriptOrigin.varp.id;
            }
            const availableVars = accessor.appletDesign.availableVars({ forProvider: varpId, includeForProvider: true });

            try {
                accessor.appletDesign.setValidatingScripts(true);
                if (!scriptCfg.id) {
                    throw new Error(messages.mes.errors.appConfig.scripts.noId);
                }

                if (!scriptCfg.script) {
                    throw new Error(messages.mes.errors.appConfig.scripts.notFound);
                }

                const {
                    modelMarkers,
                } = await validateScript(scriptCfg.script, availableVars);

                if (modelMarkers.length > 0) {
                    throw CausalError.from({
                        message: modelMarkers[0].message,
                        syntaxError: modelMarkers[0],
                        cause: `argo-script.js:${modelMarkers[0].startLineNumber}:${modelMarkers[0].startColumn}`,
                    });
                } else {
                    console.log('No errors found in script');
                }

                const varsInUse = getReferencedAppletVars(scriptCfg.script);
                const invalidVar = varsInUse.find(varName => !availableVars.includes(varName));
                if (invalidVar) {
                    throw new Error(messages.mes.errors.appConfig.scripts.varNotFound(invalidVar));
                }
                accessor.appletDesign.setValidatingScripts(false);
            } catch (err) {
                accessor.appletDesign.setValidatingScripts(false);
                Notify.error(err);
                if (varpId) {
                    const patchIfFailure: {
                        patchState: Partial<AppletDesignState>;
                        routeName: string;
                    } = {
                        patchState: { activeVarProviderId: varpId },
                        routeName: varsTabRoute,
                    };
                    accessor.appletDesign.patchAppletDesignState(patchIfFailure.patchState);
                    return patchIfFailure.routeName;
                }
                return screensTabRoute;
            }
        }
    }

    return '';
}

async function validateVarProviders (): Promise<string> {
    const entityData = accessor.entityAsType('appletDesignVersion')!;
    const { varpTypeMap } = await import('@redviking/argonaut-core-ui/src/applet/design/varps/varpTypeMap');
    const validVars: string[] = [];
    const allVars = accessor.appletDesign.availableVars();

    for (const vp of entityData.config.varProviders) {
        /**
         * how to patch the app state if validation fails... this is needed
         * to bring the user to the screen that needs fixing, and should operate differently dependending
         * on whether we are validating a MacroVarpTarget or not
         */
        const patchIfFailure: {
            patchState: Partial<AppletDesignState>;
            routeName: string;
        } = vp.type === 'macroTarget'
            ? viewMacro(vp.attrs.macroId, entityData)
            : {
                patchState: { activeVarProviderId: vp.id },
                routeName: varsTabRoute,
            };

        try {
            const inputs = vp.inputs;
            // should we check the inputs?
            if (inputs?.length) {
                // check each input
                for (const input of inputs) { // then check each input
                    if (!validVars.includes(input)) { // variable is available for this provider?
                        accessor.appletDesign.patchAppletDesignState(patchIfFailure.patchState);
                        if (allVars.includes(input)) { // variable is available ANYWHERE?
                            throw new Error(messages.mes.errors.appConfig.invalidVarOrder({ varName: input, providerName: vp.label, providerTitle: varpTypeMap[vp.type].text }));
                        } else {
                            throw new Error(messages.mes.errors.appConfig.nonexistentVarVarp({ varName: input, providerName: vp.label, providerTitle: varpTypeMap[vp.type].text }));
                        }
                    }
                }
            }

            // check each output
            for (const output of vp.outputs) {
                // have we seen this var already?
                if (validVars.includes(output)) {
                    throw new Error(messages.mes.errors.appConfig.duplicateVar({ varName: output, providerName: vp.label, providerTitle: varpTypeMap[vp.type].text }));
                }

                // ensure var name is valid
                if (output.includes(':')) {
                    accessor.appletDesign.patchAppletDesignState(patchIfFailure.patchState);
                    throw new Error(`Variable name "${output}" is invalid`);
                }

                // track this var for checking duplicates later
                validVars.push(output);
            }

            // run custom validator
            const validator = varpTypeMap[vp.type].validator;
            if (validator) {
                validator(vp);
            }
        } catch (err) {
            Notify.error(err);
            accessor.appletDesign.patchAppletDesignState(patchIfFailure.patchState);
            return patchIfFailure.routeName;
        }
    }
    return '';
}

async function validateScreens (allVars: string[]): Promise<SaveResult> {
    const { cellTypeMap } = await import('@redviking/argonaut-core-ui/src/applet/design/cells/cellTypeMap');
    const { router } = await import('src/routing');
    const entityData = accessor.entityAsType('appletDesignVersion');
    const screens = entityData?.config.screens;
    if (!entityData || !screens) {
        return {
            status: 'error',
            errorMessage: 'No screens found in applet design.',
            route: {
                name: screensTabRoute,
                query: router.currentRoute.query,
            },
        };
    }
    for (const screen of screens) {
        const cells = screen.cells;
        if (!cells.length) {
            return {
                status: 'error',
                errorMessage: `No cells found in screen ${screen.name}.`,
                route: {
                    name: screensTabRoute,
                },
            };
        }
        for (const cell of cells) {
            const validator = cellTypeMap[cell.type].validator;
            if (validator) {
                try {
                    if (cell.modifiers) {
                        Object.entries(cell.modifiers).forEach(([ _, modVal ]) => {
                            if (modVal && !allVars.includes(modVal)) {
                                throw new Error(messages.mes.errors.appConfig.nonexistentVarCell({
                                    cellLabel: cell.name,
                                    varName: modVal,
                                    cellType: cell.type,
                                }));
                            }
                        });
                    }
                    validator(cell, allVars);
                } catch (err) {
                    accessor.appletDesign.patchAppletDesignState({
                        activeScreenId: screen.id,
                        activeCellIdPerScreen: {
                            ...accessor.appletDesign.activeCellIdPerScreen,
                            [screen.id]: cell.id,
                        },
                    });
                    return {
                        status: 'error',
                        errorMessage: err,
                        route: {
                            name: screensTabRoute,
                        },
                    };
                }
            }

            if (getUnlinkedCell({ screen }) && !entityData.ignoreUnlinkedCells) {
                return {
                    status: 'modal',
                    modalComponent: () => import(/* webpackChunkName: "material" */ '../screens/ScreenSaveWarning.view.vue').then(m => m.default),
                };
            }
        }
        if (Object.values(screen.gridLayoutAspectRatios).findIndex(gridLayout => gridLayout.enabled) === -1) {
            accessor.appletDesign.patchAppletDesignState({
                activeScreenId: screen.id,
            });
            return {
                status: 'error',
                errorMessage: messages.mes.errors.appConfig.noAspectRatio,
                route: {
                    name: screensTabRoute,
                },
            };
        }
    }
    return {
        status: 'success',
    };
}

/**
 * returns how the app state should be patched to view a macro.
 */
export function viewMacro (macroId: string, entityData: EntityTypeMap['appletDesignVersion']): {
    patchState: Partial<AppletDesignState>;
    routeName: TabRoute;
} {
    // find the first cell target of a macro
    const cellTarget = accessor.appletDesign.findCellInCfg<Latest.Screen.Cells.MacroTargetCell>(cell => cell.type === 'macroTarget' && cell.attrs.macroId === macroId);
    if (cellTarget) {
        return {
            patchState: {
                activeScreenId: cellTarget.screenId,
                activeCellIdPerScreen: {
                    ...accessor.appletDesign.activeCellIdPerScreen,
                    [cellTarget.screenId]: cellTarget.cellCfg.id,
                },
            },
            routeName: screensTabRoute,
        };
    } else {
        // if no cell target, view the varp target
        const varpTarget = entityData.config.varProviders.find(v => v.type === 'macroTarget' && v.attrs.macroId === macroId);
        if (varpTarget && varpTypeMap[varpTarget.type].tab) {
            return {
                patchState: {
                    activeVarProviderId: varpTarget.id,
                },
                routeName: varsTabRoute,
            };
        }
    }
    return {
        patchState: {},
        routeName: generalTabRoute,
    };
}

function validateMacros (entityData: EntityTypeMap['appletDesignVersion'], allVars: string[]): string {
    for (const macro of entityData.config.macros || []) {
        let validator: () => void;

        switch (macro.type) {
            case 'process':
                validator = () => macroTypeMap.process.validator(macro, entityData, allVars);
                break;
            case 'paged':
                validator = () => macroTypeMap.paged.validator(macro, entityData, allVars);
                break;
            case 'materialLookup':
                validator = () => macroTypeMap.materialLookup.validator(macro, entityData, allVars);
                break;
            default: {
                // @ts-expect-error if this errors then we are missing a case
                const unknownType: string = macro.type;
                throw new Error(`Unknown macro type "${unknownType}"`);
            }
        }

        try {
            validator();
        } catch (err) {
            Notify.error(err);
            // the save result must be returned
            const { patchState, routeName } = viewMacro(macro.id, entityData);
            accessor.appletDesign.patchAppletDesignState(patchState);
            return routeName;
        }
    }
    return '';
}

export async function validateAppletDesignVersion (entityData: EntityTypeMap['appletDesignVersion']): Promise<SaveResult> {
    const { router } = await import('src/routing');
    const allVars = accessor.appletDesign.availableVars();

    // prepare a save result
    const saveResult: SaveResult = {
        status: 'success',
        route: {
            name: router.currentRoute.name as string,
            query: { ...router.currentRoute.query },
        },
    };

    if (!entityData.design.name) {
        Notify.error(messages.mes.errors.appConfig.name);
        return {
            status: 'error',
            route: { name: generalTabRoute },
        };
    }

    const pendingMedia = entityData.pendingMedia;

    if (Object.keys(pendingMedia).length > 0) {
        return Promise.resolve({
            status: 'modal',
            modalComponent: () => import(/* webpackChunkName: "material" */ '../MediaUploadDialog.view.vue').then(m => m.default),
        });
    }

    // validate macros
    const macroValidateGoToTab = validateMacros(entityData, allVars);
    if (macroValidateGoToTab) {
        return {
            status: 'error',
            route: {
                name: macroValidateGoToTab,
                query: router.currentRoute.query,
            },
        };
    }

    // validate screens
    const screensValidateGoToTab = await validateScreens(allVars);
    if (screensValidateGoToTab.status !== 'success') {
        return screensValidateGoToTab;
    }

    // scripts must be validated before varps, because a script with a syntax error can invalidate a varp (like script provider not having any outputs)
    // validate scripts
    const scriptsValidateGoToTab = await validateScripts();
    if (scriptsValidateGoToTab) {
        return {
            status: 'error',
            route: {
                name: scriptsValidateGoToTab,
                query: router.currentRoute.query,
            },
        };
    }

    // validate varps
    const varpsGoToTab = await validateVarProviders();
    if (varpsGoToTab) {
        return {
            status: 'error',
            route: {
                name: varpsGoToTab,
                query: router.currentRoute.query,
            },
        };
    }

    saveResult.route!.query!.mode = EntityDetailMode.View;
    return saveResult;
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept('@redviking/argonaut-core-ui/src/applet/design/varps/varpTypeMap');
    import.meta.webpackHot.accept('@redviking/argonaut-core-ui/src/applet/design/macros/macroTypeMap');
    import.meta.webpackHot.accept('@redviking/argonaut-core-ui/src/applet/design/cells/cellTypeMap');
    import.meta.webpackHot.accept('@redviking/argonaut-core-ui/src/routing');
}
