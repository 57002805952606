export const appletMessages = {
    en: {
        appletActionsCell: {
            editDeployment: 'Edit Deployment',
            inspectApplet: 'Inspect Applet',
            restartDeployment: 'Restart Deployment',
        },
        createDeployment: 'Create Deployment',
        deployment: {
            delete: 'Delete Deployment',
            deleteNotif: 'Deployment Deleted!',
            deletePrompt: 'Are you sure you want to delete this deployment?',
            designs: 'Linked Design Versions',
            errors: {
                noName: 'Deployment must have a name',
            },
            location: 'Deployment Location',
            name: 'Deployment Name',
            notes: 'Deployment Notes',
            saved: 'Deployment Saved!',
            sparkplug: {
                desc: 'If enabled, this deployment will be configured as a Sparkplug node. If a linked designs has any configured sparkplug metrics they will be shown below.',
                designAsSparkplugDevice: {
                    metrics: {
                        headers: {
                            allowsWrites: 'Allows Writes',
                            metricName: 'Sparkplug Metric',
                            ogVarName: 'Design Variable Name',
                        },
                        title: 'Metrics',
                    },
                    topicTitle: 'Design Sparkplug Topic:',
                },
                enableSparkplug: 'Enable Sparkplug',
                sparkplugNotUsed: 'This Deloyment Does Not Have Any Linked Designs That Require Sparkplug',
                title: 'Sparkplug Config',
            },
        },
        deploymentDetails: {
            newTitle: (runnerId: string) => `Create an Applet Deployment on Runner "${runnerId}"`,
            updateDeployment: 'Update Deployment',
        },
        inspection: {
            macroVarpFilterLabel: 'Filter logs by macro or variable provider',
        },
        json: {
            clipboard: 'Copy to clipboard',
            subtitle: 'View/edit the raw values directly, or copy+paste between configs',
            title: 'Raw Configuration Values',
        },
        navbarTitles: {
            appletDeploymentList: 'Deployments',
            appletDesignList: 'Designs',
            appletNavGroup: 'Applets',
            appletRunnerList: 'Runners',
        },
        runner: {
            unregister: 'Unregister Runner',
            unregisterDisabled: 'This Deployments linked to this runner are blocking it from being unregistered.',
            unregisterNotif: 'Runner sucessfully unregistered!',
            unregisterPrompt: 'This will remove the runner from the list of available runners. Are you sure you want to unregister this runner?',
        },
    },
};
