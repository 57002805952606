import { z } from 'zod';
import { DeployParamKind } from '../../../src/mes/deployParam.zod';
import { appletDesignVersionConfigSchema_v5, baseComparisonSchema_v5, baseOperandSchema_v5, cellCfgBaseSchema_v5, varComparisonOperandSchema_v5, varConditionSchema_v5, varpTypes_v5 } from './v5/appletDesign.v5.zod';
import { varSchema } from './var.zod';

// Applet design config imports based off of the lastest version
export const appletDesignVersionConfigSchemaLatest = appletDesignVersionConfigSchema_v5;

export const latestAppletDesignVersionNumber: Latest.AppletDesignVersionConfig['schemaVersion'] = 5;

// These are types used elsewhere in the codebase that could not be derived from the applet design config
// NOTE: we should attempt to find a way to eliminate these types and use the types from the applet design config
// The less schemas defined here the less schemas we have to maintain/ bring forward every time we create a new schema version
export const varpTypes = varpTypes_v5;
export const varConditionSchema = varConditionSchema_v5;
const baseOperandSchema = baseOperandSchema_v5;
const baseComparisonSchema = baseComparisonSchema_v5;
const varComparisonOperandSchema = varComparisonOperandSchema_v5;
const cellCfgBaseSchema = cellCfgBaseSchema_v5;

export namespace Latest {

    export type AppletDesignVersionConfig = z.infer<typeof appletDesignVersionConfigSchemaLatest>;

    export namespace Screen {

        export type Config = AppletDesignVersionConfig['screens'][number];

        export namespace Designer {

            export type GridLayoutAspectRatios = Config['gridLayoutAspectRatios'];

            export type GridLayout = GridLayoutAspectRatios[keyof GridLayoutAspectRatios];

            export type ScreenRow = GridLayout['rows'][number];
            export type ScreenColumn = GridLayout['columns'][number];
            export type LinkedGridCell = GridLayout['linkedGridCells'][number];

            export type BorderStyle = LinkedGridCell['border'];
            export type Coordinate = LinkedGridCell['coordinates'];
            export type SubGridCell = LinkedGridCell & { type: 'subGrid' };
            export type RegularGridCell = LinkedGridCell & { type: 'regular' };
            export type MarginPaddingBase = LinkedGridCell['margin'] | LinkedGridCell['padding'];
            export type BorderRadius = LinkedGridCell['borderRadius'];
        }

        export namespace Cells {

            export type AppletCell = Config['cells'][number];

            export type AppletCellBase = z.infer<typeof cellCfgBaseSchema>;

            export type AppletCellType = AppletCell['type'];

            export namespace Btn {
                export type Cell = AppletCell & { type: 'btn' };
                export type VariableBehaviorType = Exclude<Cell['attrs']['varBehavior'], undefined>['type']
            }
            export type TextCell = AppletCell & { type: 'text' };
            export type PageCell = AppletCell & { type: 'page' };
            export namespace Paged {
                export type Cell = AppletCell & { type: 'paged' };
                export type DisplayRuleEnum = Cell['attrs']['pages'][number]['rules']['displayRules'][number]['status'];
            }
            export type TimerCell = AppletCell & { type: 'timer' };
            export type MediaCell = AppletCell & { type: 'media' };
            export type InputCell = AppletCell & { type: 'input' };
            export type DialogCell = AppletCell & { type: 'dialog' };
            export type ProcessCell = AppletCell & { type: 'process' };
            export type DateTimeCell = AppletCell & { type: 'datetime' };
            export namespace AttrLookup {
                export type Cell = AppletCell & { type: 'attrLookup' };
                export type Condition = Cell['attrs']['conds'][number];
                export type PropMatchNum = Condition & { type: 'propMatchNum' };
            }
            export type MacroTargetCell = AppletCell & { type: 'macroTarget' };
            export type ProcessMediaCell = AppletCell & { type: 'processMedia' };
            export namespace MaterialLookup {
                export type Cell = AppletCell & { type: 'materialLookup' };
                export type FilterComparison = Cell['attrs']['filterConditions'][number]['comparisons'][number];
            }
            export type CircularProgressCell = AppletCell & { type: 'circularProgress' };
        }
    }

    export namespace Macros {
        export type AppletMacro = AppletDesignVersionConfig['macros'][number];

        export type AppletMacroType = AppletMacro['type'];

        export type MaterialLookup = AppletMacro & { type: 'materialLookup' };
        export type PagedMacro = AppletMacro & { type: 'paged' };
        export namespace Process {
            export type Macro = AppletMacro & { type: 'process' };
            export type CollectTriggerCfg = Macro['attrs']['collectTrigger'];
            export type DataCollection = Macro['attrs']['dataCollection'][number];
            export type LocalInputDataCollect = DataCollection & { source: 'localInput'};
            export type SparkplugNodeCfgType = (DataCollection & { source: 'sparkplug'})['varpCfg']['nodeCfg'];
        }
    }

    export namespace VarProviders {

        export type VarProvider = AppletDesignVersionConfig['varProviders'][number];

        export type VarProviderType = VarProvider['type'];

        export type PageProvider = VarProvider & { type: 'page' };
        export type PagedProvider = VarProvider & { type: 'paged' };
        export type FormattedProvider = VarProvider & { type: 'formatted' };
        export type LockingProvider = VarProvider & { type: 'locking' };
        export type SliceProvider = VarProvider & { type: 'slice' };
        export namespace Local {
            export type Provider = VarProvider & { type: 'local' };
            export type VarType = Provider['attrs']['vars'][number];
            export type VarCfgDeployParam = VarType & { deployParamKind: DeployParamKind.localVarValue };
            export type VarVal = VarType['initialVal'];
        }
        export namespace Comparative {
            export type Provider = VarProvider & { type: 'comparative' };
            export type Assignment = Provider['attrs']['assignments'][number];
            export type Comparison = Assignment['comparisons'][number];
            export type EqComparison = Comparison & { type: 'eq' };
            export type TypeOperand = Comparison['operands'][number] & { type: 'type' };
        }
        export namespace MaterialId {
            export type Provider = VarProvider & { type: 'materialId' };
            export type UdfSource = Provider['attrs']['udfOutputs'][number]['sources'][number];
        }
        export type MqttPublishProvider = VarProvider & { type: 'mqttPublish' };
        export type MqttSubscribeProvider = VarProvider & { type: 'mqttSubscribe' };
        export type VarStatusProvider = VarProvider & { type: 'varStatus' };
        export type ProcessProvider = VarProvider & { type: 'process' };
        export type MacroVarpTarget = VarProvider & { type: 'macroTarget' };
        export namespace Sparkplug {
            export type Provider = VarProvider & { type: 'sparkplug' };
            export type AppletNodeCfg = Provider['attrs']['nodeCfg'];
            export type AppletNodeCfgDeployParam = AppletNodeCfg & { type: 'deployParam' };
        }
        export type SparkplugDeviceWriteProvider = VarProvider & { type: 'sparkplugDeviceWrite' };
        export namespace CreateMaterial {
            export type Provider = VarProvider & { type: 'createMaterial' };
            export type MaterialModel = Provider['attrs']['materialModels'][number];
            export type SnCfgFormatted = MaterialModel['snCfg'] & { type: 'formatter' };
            export type UdfValues = MaterialModel['udfs'];
        }
        export type TimerProvider = VarProvider & { type: 'timer' };
        export namespace Calendar {
            export type Provider = VarProvider & { type: 'calendar' };
            export type UdfEvent = Provider['attrs']['udfCalendarEvents'][number];
            export type EventOccurance = UdfEvent['occurance'];
        }
        export type ScriptProvider = VarProvider & { type: 'script' };
        export type UdpSendProvider = VarProvider & { type: 'udpSend' };
        export type UdpReceiveProvider = VarProvider & { type: 'udpReceive' };
        export type TcpClientProvider = VarProvider & { type: 'tcpClient' };
        export type TcpServerProvider = VarProvider & { type: 'tcpServer' };
    }

    // Conditional types
    export type BaseOperand = z.infer<typeof baseOperandSchema>;
    export type BaseComparison = z.infer<typeof baseComparisonSchema>;
    export type BaseComparison2 = Pick<VarProviders.Comparative.Provider['attrs']['assignments'][number]['comparisons'][number], 'not' | 'type' | 'operands'>;
    export type BaseOperand2 = Pick<BaseComparison2['operands'][number], 'type'>;

    export type VarComparisonOperand = z.infer<typeof varComparisonOperandSchema>;
    export type VarCondition = z.infer<typeof varConditionSchema>;
    export type VarComparison = VarCondition['comparisons'][number];
    export type VarOperand = VarComparison['operands'][number];

    // Variable types
    export type AppletVar = z.infer<typeof varSchema>;

    // Sparkplug types
    export type SparkplugDeviceMetric = AppletDesignVersionConfig['sparkplugDeviceMetrics'][number];
    export type SparkplugDeviceMetricPermissions = SparkplugDeviceMetric['permissions'];

    // Script types
    export type RuntimeScriptCfg = AppletDesignVersionConfig['scripts'][number];

    export type AllowedModifiers<C extends string> = {
        attr: C;
        displayName: string;
        allowedVarTypes?: string[];
    }[];
}
