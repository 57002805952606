import { z } from 'zod';
import { appletDesignVersionConfigSchema_v4, baseComparisonSchema_v4, baseOperandSchema_v4, cellCfgBaseSchema_v4, gridLayoutSchema_v4, regularGridCellSchema_v4, screenSchema_v4, subGridCellSchema_v4, varComparisonOperandSchema_v4, varConditionSchema_v4, varpTypes_v4 } from '../v4/appletDesign.v4.zod';
import { cellSchema_v5 } from './cells.v5.zod';
import { varpSchema_v5 } from './var-providers/var-providers.v5.zod';
import { udpSendProviderSchema } from './var-providers/udp-send.v5.zod';
import { udpReceiveProviderConfigSchema } from './var-providers/udp-receive.v5.zod';
import { tcpClientProviderSchema } from './var-providers/tcp-client.v5.zod';
import { tcpServerProviderSchema } from './var-providers/tcp-server.v5.zod';

export const regularGridCellSchema_v5 = regularGridCellSchema_v4.extend({
    borderRadius: z.number().optional(),
});

export const subGridCellSchema_v5 = subGridCellSchema_v4.extend({
    borderRadius: z.number().optional(),
});

export const linkedGridCellSchema_v5 = z.discriminatedUnion('type', [ regularGridCellSchema_v5, subGridCellSchema_v5 ]);

export const gridLayoutSchema_v5 = gridLayoutSchema_v4.extend({
    borderRadius: z.number().optional(),
    linkedGridCells: z.array(linkedGridCellSchema_v5),
});
export const gridLayoutAspectRatioSchema_v5 = z.object({
    mobile: gridLayoutSchema_v5,
    tablet: gridLayoutSchema_v5,
    desktop: gridLayoutSchema_v5,
    largeDisplay: gridLayoutSchema_v5,
});

const appletDesignScreenSchema_v5 = screenSchema_v4.omit({
    cells: true,
    gridLayoutAspectRatios: true,
}).extend({
    gridLayoutAspectRatios: gridLayoutAspectRatioSchema_v5,
    cells: z.array(z.lazy(() => cellSchema_v5)),
});

export const appletDesignVersionConfigSchema_v5 = appletDesignVersionConfigSchema_v4.omit({
    screens: true,
    varProviders: true,
    schemaVersion: true,
}).extend({
    schemaVersion: z.literal(5),
    varProviders: z.array(varpSchema_v5),
    screens: z.array(appletDesignScreenSchema_v5),
    fontFamily: z.union([
        z.literal('serif'),
        z.literal('sans-serif'),
        z.literal('monospace'),
        z.literal('cursive'),
        z.literal('fantasy'),
        z.literal('system-ui'),
    ]).default('monospace'),
});

export type AppletDesignVersionConfig_V5 = z.infer<typeof appletDesignVersionConfigSchema_v5>;

export const cellCfgBaseSchema_v5 = cellCfgBaseSchema_v4;

export const varConditionSchema_v5 = varConditionSchema_v4;

export const varpTypes_v5 = {
    ...varpTypes_v4,
    udpSend: udpSendProviderSchema,
    udpReceive: udpReceiveProviderConfigSchema,
    tcpClient: tcpClientProviderSchema,
    tcpServer: tcpServerProviderSchema,
};

export const varComparisonOperandSchema_v5 = varComparisonOperandSchema_v4;

export const baseOperandSchema_v5 = baseOperandSchema_v4;

export const baseComparisonSchema_v5 = baseComparisonSchema_v4;
