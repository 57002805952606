import { z } from 'zod';
import {
    appletDesignVersionConfigSchema_v3,
    gridLayoutSchema_v3,
    regularGridCellSchema_v3,
    screenSchema_v3,
    subGridCellSchema_v3,
} from '../v3/applet-design.v3.zod';
import { cellCfgBaseSchema_v3 } from '../v3/cell-types/cell-base.v3.zod';
import { varpTypes_v3 } from '../v3/var-providers.v3.zod';
import { baseComparisonSchema_v3, baseOperandSchema_v3, varComparisonOperandSchema_v3, varConditionSchema_v3 } from '../v3/var-providers/conditional.v3.zod';

export const regularGridCellSchema_v4 = regularGridCellSchema_v3.omit({
    cssCellId: true,
});

export const subGridCellSchema_v4 = subGridCellSchema_v3.omit({
    cssCellId: true,
});

export const linkedGridCellSchema_v4 = z.discriminatedUnion('type', [ regularGridCellSchema_v4, subGridCellSchema_v4 ]);

export const gridLayoutSchema_v4 = gridLayoutSchema_v3.extend({
    enabled: z.boolean().default(true),
    linkedGridCells: z.array(linkedGridCellSchema_v4),
});

export const gridLayoutAspectRatioSchema_v4 = z.object({
    mobile: gridLayoutSchema_v4,
    tablet: gridLayoutSchema_v4,
    desktop: gridLayoutSchema_v4,
    largeDisplay: gridLayoutSchema_v4,
});

export const screenSchema_v4 = screenSchema_v3.omit({
    gridLayout: true,
}).extend({
    gridLayoutAspectRatios: gridLayoutAspectRatioSchema_v4,
});

export type ScreenCfg_V4 = z.infer<typeof screenSchema_v4>;

export const appletDesignVersionConfigSchema_v4 = appletDesignVersionConfigSchema_v3.omit({
    screens: true,
    schemaVersion: true,
}).extend({
    schemaVersion: z.literal(4),
    screens: z.array(screenSchema_v4),
});

export type AppletDesignVersionConfig_V4 = z.infer<typeof appletDesignVersionConfigSchema_v4>;

export type ScriptProviderCfg_V4 = AppletDesignVersionConfig_V4['varProviders'][number] & { type: 'script' };

export const cellCfgBaseSchema_v4 = cellCfgBaseSchema_v3;

export const varConditionSchema_v4 = varConditionSchema_v3;

export const varComparisonOperandSchema_v4 = varComparisonOperandSchema_v3;

export const baseOperandSchema_v4 = baseOperandSchema_v3;

export const baseComparisonSchema_v4 = baseComparisonSchema_v3;

export const varpTypes_v4 = varpTypes_v3;
